import Link from "next/link";
import { usePathname } from "next/navigation";
import FixTheOpsLogo from "./FixTheOpsLogo";

export default function HeaderLanding() {
  const pathname = usePathname();

  return (
    <>
      <header className="relative">
        <div className="relative">
          <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <div className="flex items-center justify-between py-6">
              <div className="flex justify-start">
                <Link href="https://fixtheops.dev" aria-label="FixTheOps logo">
                  <FixTheOpsLogo />
                </Link>
              </div>
              {/* <div className="-my-2 -mr-2 md:hidden"></div> */}

              {/* <div className="hidden space-x-10 md:flex">
                  <a
                    href=""
                    className="inline-block rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                  >
                    Changelog
                  </a>
                </div> */}
              <div className="hidden space-x-10 md:flex">
                <Link
                  href="/changelog"
                  className={`inline-block rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900 ${
                    pathname?.includes("changelog")
                      ? "text-fuchsia-800 font-medium"
                      : ""
                  }`}
                >
                  Changelog
                </Link>
                <Link
                  href="/about"
                  className={`inline-block rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900 ${
                    pathname?.includes("about")
                      ? "text-fuchsia-800 font-medium"
                      : ""
                  }`}
                >
                  About
                </Link>
                <Link
                  href="/auth/signin"
                  className={`inline-block rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900 ${
                    pathname?.includes("auth/signin")
                      ? "text-fuchsia-800 font-medium"
                      : ""
                  }`}
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
