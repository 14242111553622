// useful to avoid eslint to add noreferrer to all links
/* eslint-disable react/jsx-no-target-blank */

import Head from "next/head";
import Link from "next/link";
// import JoinWaitingList from './JoinWaitingList'
import dynamic from "next/dynamic";
const JoinWaitingList = dynamic(
  () => import("../components/Landing/JoinWaitingList")
);
// import JoinWaitingList from '../components/Landing/JoinWaitingList'
import prisma from "@lib/prismadb";
import Image from "next/image";
import screenshot from "../public/landing/challenge.png";
import challengePageScreenshot from "../public/landing/challenges-page.png";
import me from "../public/landing/me.jpg";

// images
import helm from "../public/icons-landing/helm.png";
import kubernetes from "../public/icons-landing/kubernetes.png";
import grafana from "../public/icons-landing/grafana.png";
import prometheus from "../public/icons-landing/prometheus.png";
import docker from "../public/icons-landing/docker.png";
import DefaultTags from "../components/DefaultTags";
import HeaderLanding from "@components/Landing/Header";
import LandingPageFooterComponent from "@components/Landing/Footer";

// Cool background color
const BlurSVG = () => {
  return (
    <div className="absolute inset-x-0 top-[calc(100%-20rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-35rem)]">
      <svg
        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[35rem]"
        viewBox="0 0 1155 678"
      >
        <path
          fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            {/* Matching blue-600 and fuchsia-600 */}
            <stop stopColor="#2563eb" />
            <stop offset={1} stopColor="#c026d3" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default function HomePage({
  challengesCount,
  challengesSolvedCount,
  usersCount,
  environementsCount,
}: {
  challengesCount: number;
  challengesSolvedCount: number;
  usersCount: number;
  environementsCount: number;
}) {
  const stats = [
    { name: "Users on FixTheOps", stat: usersCount },
    {
      name: "Kubernetes clusters created",
      stat: environementsCount,
    },
    { name: "Challenges solved", stat: challengesSolvedCount },
  ];

  const testimonials = [
    {
      from: "Me 👨‍💻",
      text: "If Leetcode & HackTheBox had a love child",
    },
    {
      from: "My mom 🦸‍♀️",
      text: "Looks great, keep going!",
    },
    {
      from: "My girlfriend 🤦‍♀️",
      text: "Stop working on this stupid project and pay attention to me",
    },
    {
      from: "@Teyhouse (Security Engineer / DevSecOps)",
      text: "A fun and rewarding experience based on real-life troubleshooting. The only thing that made me stop solving challenges was server maintenance.",
    },
    {
      from: "Uros (DevOps Engineer)",
      text: "Fun, insightful, educational! Feels like overthewire.org but for Kubernetes. It has a playful approach for testing how deep your knowledge truly goes. Can't wait for more challenges!",
    },
  ];

  const head = {
    title: "FixTheOps | The Cloud Native Hands-on platform for DevOps",
    description:
      "Find your way through multiple challenges to improve your DevOps skills on FixTheOps. Everything Cloud-Native related: Kubernetes, Docker, Helm, Prometheus...",
    image: "https://fixtheops.dev/fixtheops-logo/fixtheops-logo-bg-white.png",
    type: "website",
    url: "https://fixtheops.dev",
  };

  return (
    <>
      <Head>
        <DefaultTags />
        <title>{head.title}</title>
        <meta name="description" content={head.description}></meta>
        <link rel="canonical" href="https://fixtheops.dev" key="canonical" />
        <meta name="robots" content="all" key="robots" />

        {/* Open Graph */}
        <meta property="og:title" content={head.title} />
        <meta property="og:type" content={head.type} />
        <meta property="og:description" content={head.description} />
        <meta property="og:url" content={head.url} />
        <meta property="og:image" content={head.image} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@fixtheops" />
        <meta name="twitter:title" content={head.title} />
        <meta name="twitter:description" content={head.description} />
        <meta name="twitter:image" content={head.image} />

        {/* <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" /> */}
        {/* <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> */}
        {/* <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" /> */}
        <link rel="manifest" href="/site.webmanifest" />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicons/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicons/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicons/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicons/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicons/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicons/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicons/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicons/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicons/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicons/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>

      <div className="landing">
        <HeaderLanding />
        <main>
          <section className="bg-white">
            <div className="relative mx-auto max-w-screen-xl py-8 sm:px-4 text-center lg:py-16 lg:px-12">
              <h1 className="mb-4 px-10 font-bold leading-none tracking-tighter text-gray-900 text-4xl md:text-5xl lg:text-6xl max-w-screen-lg mx-auto">
                {/* The Cloud Native  platform */}
                The Cloud Native{" "}
                <span className="bg-gradient-to-r from-blue-600 to-fuchsia-600 bg-clip-text text-transparent">
                  Hands-on
                </span>{" "}
                {/* <span
                  className="animate-spin"
                  style={{
                    backgroundClip: "text !important",
                    WebkitBackgroundClip: "text !important",
                    background:
                      "linear-gradient(90deg,#2563eb,#c026d3) 0 0 /400% 100%",
                    color: "transparent",
                    animation: "spin 1s linear infinite",
                  }}
                >
                  Hands-on
                </span>{" "} */}
                platform for DevOps
              </h1>
              {/* <p className="text-md mb-10 px-4 font-normal text-gray-500 md:px-16 lg:px-48 lg:text-xl xl:px-70 whitespace-pre-line">
                Find your way through multiple challenges to improve your DevOps
                skills on FixTheOps.
                <br />
                Everything Cloud-Native related: Kubernetes, Docker, Helm,
                Prometheus...
              </p> */}
              <p className="mt-20"></p>
              {/* Positioned to the latest "positioned" element, ie relative */}

              {/* 
              CTA BUTTON
            */}
              <section className="bg-gradient-to-r from-blue-600 to-fuchsia-600 rounded p-1">
                <JoinWaitingList />
              </section>
              {/* <div className='my-8 flex flex-row justify-center space-x-8'>
              <Link href="/challenges" passHref>
                <ButtonPrimary></ButtonPrimary>
              </Link>
            </div> */}
            </div>
          </section>

          {/*
           * Community vs
           * Companies & Candidates
           * vs Sponsors
           */}

          {/*
           * Number of challenges
           */}
          <section className="bg-white">
            <div className="relative mx-auto max-w-screen-xl py-8 px-4 text-center lg:pt-8 lg:pb-8 lg:px-12">
              <h2 className="mb-4 px-10 text-4xl font-semibold leading-none tracking-tighter text-gray-900 md:text-5xl lg:text-5xl">
                <span className="bg-gradient-to-r from-blue-600 to-fuchsia-600 bg-clip-text text-transparent font-extrabold">
                  {challengesCount || 0}
                </span>{" "}
                challenges
              </h2>
              <p className="text-md mb-10 px-4 font-normal text-gray-500 md:px-16 lg:px-48 lg:text-xl xl:px-60">
                And more to come...
              </p>
              {/* FLOATING ICONS */}
              <div
                id="icons"
                className="flex mt-8 justify-between px-4 md:px-16 lg:px-48 xl:px-60"
              >
                <a
                  href="https://www.docker.com/"
                  target={"_blank"}
                  rel="noopener"
                  className="hover:opacity-50"
                >
                  <Image
                    src={docker}
                    alt="docker logo | FixTheOps"
                    title="docker logo | FixTheOps"
                    placeholder="blur"
                    className=" w-12"
                    width="48"
                    height="48"
                  />
                </a>
                <a
                  href="https://prometheus.io/"
                  target={"_blank"}
                  rel="noopener"
                  className="hover:opacity-50"
                >
                  <Image
                    src={prometheus}
                    alt="prometheus logo | FixTheOps"
                    title="prometheus logo | FixTheOps"
                    placeholder="blur"
                    className=" w-12"
                    width="48"
                    height="48"
                  />
                </a>
                <a
                  href="https://kubernetes.io/"
                  target={"_blank"}
                  rel="noopener"
                  className="hover:opacity-50"
                >
                  <Image
                    src={kubernetes}
                    alt="kubernetes logo | FixTheOps"
                    title="kubernetes logo | FixTheOps"
                    placeholder="blur"
                    className=" w-12"
                    width="48"
                    height="48"
                  />
                </a>

                <a
                  href="https://helm.sh/"
                  target={"_blank"}
                  className="hover:opacity-50"
                  rel="noopener"
                >
                  <Image
                    src={helm}
                    alt="helm logo | FixTheOps"
                    title="helm logo | FixTheOps"
                    placeholder="blur"
                    className=" w-12"
                    width="48"
                    height="48"
                  />
                </a>
                <a
                  href="https://grafana.com/"
                  target={"_blank"}
                  className="hover:opacity-50"
                  rel="noreferrer"
                >
                  <Image
                    src={grafana}
                    alt="grafana logo | FixTheOps"
                    title="grafana logo | FixTheOps"
                    placeholder="blur"
                    className=" w-12"
                    width="48"
                    height="48"
                  />
                </a>
              </div>
            </div>
          </section>

          {/* NEW SECTION SCREENSHOT */}
          <div className="relative isolate overflow-hidden">
            <div className="relative py-24 sm:py-32">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                  <div className="lg:pr-4 lg:pt-4">
                    <div className="lg:max-w-lg">
                      <h2 className="text-base font-semibold leading-7 text-indigo-600">
                        Learn fast
                      </h2>
                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Challenges for all levels
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                        Each challenge tries to relate to a real-world situation
                        one can encounter. Kubernetes challenges gives you
                        access to a remote cluster and you have to find the
                        solution to pass.
                      </p>

                      {/* <div className="mt-8">
                      <a
                        href="#"
                        className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Get started
                      </a>
                    </div> */}
                      <figure className="mt-16 border-l border-gray-200 pl-8 text-gray-600">
                        <blockquote className="text-base leading-7">
                          <p>
                            “A few years ago, I used to learn Cybersecurity on
                            HackTheBox and Root-me. The DevOps ecosystem
                            deserves a similar platform focused on topics like
                            Kubernetes, Observability, & Containers.”
                          </p>
                        </blockquote>
                        <figcaption className="mt-6 flex gap-x-4 text-sm leading-6">
                          <Image
                            src={me}
                            alt=""
                            className="h-6 w-6 flex-none rounded-full"
                          />
                          <div>
                            <Link
                              href="https://www.linkedin.com/in/fran%C3%A7ois-le-pape/"
                              className="font-semibold text-gray-900"
                            >
                              François LP
                            </Link>{" "}
                            {/* – Building FixTheOps */}
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  <Image
                    src={challengePageScreenshot}
                    alt="Product screenshot"
                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
                    width={2432}
                    height={1442}
                  />
                  <BlurSVG />
                </div>
              </div>
            </div>

            {/*
             * Testimonials
             */}
            <section className="relative bg-transparent overflow-hidden pt-12 pb-20 lg:pb-24 xl:pb-24">
              <div className="mx-auto pl-4 md:p-0 max-w-3xl md:text-center">
                <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
                  What people say about <strong>FixTheOps</strong>
                </h2>
                <p className="mt-4 mb-10 text-lg tracking-tight text-slate-700">
                  100% trustworthy testimonials
                </p>
              </div>
              <div className="container m-auto grid grid-cols-1 sm:grid-cols-3 gap-4 max-w-6xl">
                {testimonials.map((testimonial, key) => {
                  return (
                    <div
                      className="block p-6 border border-gray-200 rounded-lg bg-white m-2"
                      key={key}
                    >
                      <span className="text-gray-900 text-md leading-tight font-bold mb-2">
                        {testimonial.from}
                      </span>
                      <p className="text-gray-700 text-base">
                        &quot;{testimonial.text}&quot;
                      </p>
                    </div>
                  );
                })}
              </div>
            </section>

            {/*
             * Stats
             */}
            <section className="relative bg-transparent overflow-hidden pt-12 pb-20 lg:pb-24 xl:pb-24">
              <div className="mx-auto pl-4 md:p-0 max-w-3xl md:text-center">
                <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
                  And <strong>growing</strong> every day...
                </h2>
                {/* <p className="mt-4 mb-10 text-lg tracking-tight text-slate-700">
                  100% trustworthy testimonials
                </p> */}
              </div>
              <div className="relative mx-auto max-w-screen-xl py-8 px-4 text-center lg:pt-8 lg:pb-8 lg:px-12">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {stats.map((item) => (
                    <div
                      key={item.name}
                      className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                    >
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {item.name}
                      </dt>
                      <dd
                        className="mt-1 text-3xl font-semibold tracking-tight bg-gradient-to-r from-blue-600 to-fuchsia-600 bg-clip-text text-transparent font-extrabold
                    "
                      >
                        <span className="text-3xl font-semibold tracking-tight bg-gradient-to-l from-blue-600 to-fuchsia-600 bg-clip-text text-transparent font-extrabold">
                          + {item.stat}
                        </span>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </section>
          </div>
        </main>
        <LandingPageFooterComponent />
      </div>
    </>
  );
}

// This function gets called at build time
export async function getStaticProps() {
  const challengesCount = await prisma.challenge.count();

  // stats
  const challengesSolvedCount = await prisma.usersOnChallenges.count();
  const environementsCount = await prisma.environment.count();
  const usersCount = await prisma.user.count();

  const roundToTen = (num: number) => Math.floor(num / 10) * 10;

  return {
    props: {
      challengesCount,
      challengesSolvedCount: roundToTen(challengesSolvedCount) || 1,
      environementsCount: roundToTen(environementsCount) || 1,
      usersCount: roundToTen(usersCount) || 1,
    },
  };
}
